@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px #e8f0fe inset;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

.fix-blur {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

.scroll-transparent::-webkit-scrollbar-track {
  background: #02031400 !important;
}
.scroll-transparent::-webkit-scrollbar-thumb {
  border: 4px solid #02031400 !important;
}

.scroll-transparent::-webkit-scrollbar-thumb {
  background-color: #919ba3;
}

dialog {
  @apply text-white;
}

body,
.body-bg {
  color: rgb(var(--foreground-rgb));
  background-color: #020314;
  /* background-image: linear-gradient(to right, #000000, #001435); */
}

body:fullscreen {
  overflow: auto;
}

body.modal-open {
  overflow: hidden;
}

@layer base {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

.old-page-scrollbar *::-webkit-scrollbar {
  width: 14px;
  overflow: visible;
}

.old-page-scrollbar *::-webkit-scrollbar-track {
  background: #020314;
}

.old-page-scrollbar *::-webkit-scrollbar-thumb {
  background-color: #5e6a74;
  border-radius: 0.5rem;
  border: 4px solid #020314;
  width: 10px;
  background-clip: padding-box;
  margin: 4px;
}

.old-page-scrollbar *::-webkit-scrollbar-corner {
  background-color: transparent;
}

dialog::backdrop {
  background: rgba(0, 0, 0, 0.5);
}

@layer utilities {
  .calendar-picker-invert ::-webkit-calendar-picker-indicator {
    filter: invert(1);
    cursor: pointer;
  }
  @variants responsive {
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.PDFPage > canvas {
  max-width: 100%;
  height: auto !important;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}